/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { Shake } from 'reshake';
import { Trans } from 'react-i18next';
import Store from '../store';

function CheckBox() {
  const store = Store.useContainer();
  return (
    <>
      <div className="cgu">
        <Shake h={10} v={0} r={0} q={4} active={store.shake && !store.cgu} fixed>
          <Checkbox
            label={(
              <label
                style={{
                  textAlign: 'start',
                }}
              >
                <Trans i18nKey="cgu">
                  J&apos;accepte les <a
                    href={
                    window.location.pathname.indexOf('/s/') === 0
                      ? `/s/${window.location.pathname.split('/')[2]}/cgu`
                      : '/cgu'
                  }
                    rel="noopener noreferrer"
                    target="_blank"
                  // eslint-disable-next-line react/jsx-one-expression-per-line
                  >CGV</a> et la <a href="https://audiomeans.fr/politique-de-confidentialite" rel="noopener noreferrer" target="_blank">Politique de Confidentialité</a>.
                </Trans>
              </label>
            )}
            checked={store.cgu}
            onChange={(evt, { checked }) => {
              store.setCgu(checked);
            }}
          />
        </Shake>
      </div>

    </>
  );
}

export default CheckBox;
